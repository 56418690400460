<template>
  <nav class="top-0 absolute z-50 w-full flex items-center justify-center px-2 py-3 navbar-expand-lg">
    <div class="container px-4 mx-auto flex justify-center items-center">
      <div class="relative flex justify-center items-center">
        <router-link class="mt-16 text-center text-blueGray-600 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0" to="/">
            <img style="max-width: 120px; height: auto;" class="logo" src="../../assets/img/conan.png"/>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  components: {
  },
};
</script>
