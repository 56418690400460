<template>
    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-4/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold">
                <br/>
                <small>Wachtwoord opnieuw instellen</small>
              </div>
              <form @submit.prevent="performPasswordReset">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="new-password">Nieuw wachtwoord</label>
                  <input v-model="newPassword" type="password" id="new-password" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Nieuw wachtwoord" required />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="confirm-password">Bevestig nieuw wachtwoord</label>
                  <input v-model="confirmPassword" type="password" id="confirm-password" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Bevestig nieuw wachtwoord" required />
                </div>
                <div class="text-center mt-6">
                  <button class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Wachtwoord opnieuw instellen</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CardAlert
      v-show="alertMessage"
      ref="cardAlert"
      :message="alertMessage"
      @close="alertMessage = 'Hello'"
    />
    </div>
  </template>
  
  <script>
import CardAlert from '@/components/Cards/CardAlert.vue';
import axios from 'axios';

export default {
    components: {
      CardAlert,
    },
    data() {
      return {
        alertMessage: '',
        newPassword: '',
        confirmPassword: '',
        token: '', // Assuming you have a way to pass the token (e.g., via route query or params)
      };
    },
    methods: {
      async performPasswordReset() {
        if (this.newPassword !== this.confirmPassword) {
          alert('Passwords do not match.');
          return;
        }
        try {
          await axios.post('https://conan.antsolutions.be/conanbe/updatePassword/', {
            token: this.token,
            newPassword: this.newPassword,
          });
          this.alertMessage = ('Paswoord succesvol veranderd!');
          this.$refs.cardAlert.showAlert();
          setTimeout(this.$router.push('/login'), 3000); // Adjust the path as necessary
        } catch (error) {
          console.error('Error resetting password:', error.response.data);
          this.alertMessage = ('Er liep iets mis! Probeer opnieuw.');
          this.$refs.cardAlert.showAlert();
        }
      }
    },
    created() {
      // Example of extracting the token from the query parameters
      this.token = this.$route.query.token;
    }
  };
  </script>