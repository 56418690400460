<template>
  <div
    class="relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg"
    :class="[backgroundColor]"
  >
    <div class="flex-auto p-4">
      <div v-if="loading">
        <Skeleton :count="1" height="100px" />
      </div>
      <div v-else>
        <div class="flex flex-wrap">
          <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
            <h5 class="text-blueGray-400 uppercase font-bold text-xs">
              {{ statSubtitle }}
            </h5>
            <span class="font-semibold text-xl text-blueGray-700">
              {{ statTitle }}
            </span>
          </div>
          <div class="relative w-auto pl-4 flex-initial">
            <div
              class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full"
              :class="[statIconColor]"
            >
              <i :class="[statIconName]"></i>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <p class="text-sm text-blueGray-400">
            <span class="mr-2" :class="[computedStatPercentColor]">
              <i :class="[computedStatIcon]"></i>
              {{ formattedStatPercent }}%
            </span>
            <span class="whitespace-nowrap">{{ statDescripiron }}</span>
          </p>
          <!-- Magnifying Glass Icon Button -->
          <button
            type="button"
            @click="toggleModal"
            class="text-gray-500 hover:text-gray-700"
            style="margin-top:15px;"
          >
            <i class="fas fa-search fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- Magnifying glass icon button -->

    <!-- Modal -->
    <custom-modal :is-visible.sync="showModal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><b>{{ modalTitle }}</b></h5>
            <button type="button" class="btn-close" @click="showModal = false" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs">
              <li class="nav-item" v-for="(value, name) in resultObject" :key="name">
                <a
                  :class="['nav-link', { active: activeTab === name }]"
                  @click.prevent="activeTab = name"
                  href="#"
                  role="tab"
                >{{ name }}</a>
              </li>
            </ul>
            <!-- Tab Content -->
            <div class="tab-content mt-3">
              <div v-for="(items, name) in resultObject" :key="name">
                <div v-if="activeTab === name">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" v-for="(header, index) in items[0]" :key="index">{{ header }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, rowIndex) in items.slice(1)" :key="rowIndex">
                        <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="modal-close-btn" @click="showModal = false">Sluiten</button>
          </div>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from './CustomModal.vue';
import { Skeleton } from 'vue-loading-skeleton';
import 'vue-loading-skeleton/dist/style.css';
import './custom-bootstrap.css';

export default {
  name: "card-stats",
  components: {
    CustomModal,
    Skeleton,
  },
  props: {
    statPercent: {
      type: [Number, String],
      default: 0,
    },
    backgroundColor: {
      type: String,
      default: "bg-white"
    },
    statSubtitle: {
      type: String,
      default: "Traffic",
    },
    statTitle: {
      type: String,
      default: "350,897",
    },
    statDescripiron: {
      type: String,
      default: "Since last month",
    },
    statIconName: {
      type: String,
      default: "far fa-chart-bar",
    },
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
    resultObject: {
      type: Object,
      default: () => ({})
    },
    modalId: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      default: "Details",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedStatIcon() {
      const percent = parseFloat(this.statPercent);
      if (percent > 0) {
        return 'fas fa-arrow-up';
      } else if (percent < 0) {
        return 'fas fa-arrow-down';
      } else {
        return 'fas fa-minus';
      }
    },
    computedStatPercentColor() {
      const percent = parseFloat(this.statPercent);
      if (percent > 0) {
        return "text-red-500";
      } else if (percent < 0) {
        return "text-emerald-500";
      } else {
        return "text-gray-500";
      }
    },
    formattedStatPercent() {
      const percent = parseFloat(this.statPercent);
      return percent !== 0 ? percent.toString() : '-';
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    }
  },
  data() {
    return {
      showModal: false,
      activeTab: '',
    };
  },
  mounted() {
    this.activeTab = Object.keys(this.resultObject)[0] || 'overzicht';
  },
};
</script>

<style scoped>
/* Style for tabs */
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #007bff;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

/* Close Button with Soft Red Background */
.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #ffcccc; /* Soft red */
  color: #333; /* Text color for contrast */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: #ff9999; /* Slightly darker red on hover */
}

/* Spreadsheet-like table styles */
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
</style>