<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            {{ chartTitle }}
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold">
            {{ chartSubtitle }}
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto relative">
      <div v-if="loading">
        <Skeleton height="250px" />
      </div>
      <div v-else>
        <line-chart v-if="isDataAvailable" :data="formattedChartData" :options="chartOptions"></line-chart>
        <div v-else class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <span class="text-center text-gray-500">
            Er zijn geen gegevens om te tonen
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import the Line component from vue-chartjs
import { Line } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';
import { Skeleton } from 'vue-loading-skeleton';
import 'vue-loading-skeleton/dist/style.css';

Chart.register(...registerables);

export default {
  components: {
    LineChart: Line,
    Skeleton,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
    chartTitle: {
      type: String,
      default: 'Line Chart',
    },
    chartSubtitle: {
      type: String,
      default: 'Trend',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDataAvailable() {
      return this.chartData.datasets && this.chartData.datasets.some(dataset => dataset.data && dataset.data.length > 0);
    },
    formattedChartData() {
      if (this.chartData && Object.keys(this.chartData).length > 0) {
        return this.chartData;
      }
      return {
        labels: [],
        datasets: [],
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: 'bottom',
          labels: {
            fontColor: 'rgba(0,0,0,.4)',
          },
        },
        lineTension: 0.6,
        // Other options...
      };
    },
  },
};
</script>