<template>
    <div v-if="isVisible" class="modal-backdrop" @click.self="closeModal">
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:isVisible', false);
    }
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && this.isVisible) {
        this.closeModal();
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.closeModal);
  }
};
  </script>
  
  <style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
  }
  
  .modal-content {
    z-index: 9999;
    min-height: 80%;
    max-height: 80%;
    width: 75vw;
    overflow-y: auto;
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative; /* For positioning the close button */
  }
  </style>