<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            {{ chartTitle }}
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold">
            {{ chartSubtitle }}
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto relative">
      <div v-if="loading">
        <Skeleton height="250px" />
      </div>
      <div v-else>
        <bar-chart v-if="isDataAvailable" :data="formattedChartData" :options="chartOptions"></bar-chart>
        <div v-else class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <span class="text-center text-gray-500">
            Er zijn geen gegevens om te tonen
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Skeleton } from 'vue-loading-skeleton';
import 'vue-loading-skeleton/dist/style.css';

export default {
  components: {
    BarChart: Bar,
    Skeleton,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({
        labels: [],
        datasets: []
      })
    },
    chartTitle: {
      type: String,
      default: 'Bar Chart'
    },
    chartSubtitle: {
      type: String,
      default: 'Distribution'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isDataAvailable() {
      return this.chartData.datasets && this.chartData.datasets.some(dataset => dataset.data && dataset.data.length > 0);
    },
    formattedChartData() {
      return this.chartData;
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: false,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        }
      };
    }
  }
}
</script>