<template>
    <div>
      <button v-if="sites" class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click="openModal = true">Upload from SharePoint</button>
  
      <div v-if="openModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="openModal = false">&times;</span>
          <h2>Kies een Sharepoint site</h2><br/>
          
          <select v-model="selectedSite" @change="getDocumentLibraries">
            <option disabled value="">Kies een site</option>
            <option v-for="(site, index) in sites" :value="site.id" :key="index">{{ site.displayName }}</option>
          </select>
          
            <div v-if="selectedSite">
            <br/>
            <label>Kies een document library:</label><br/>
            <select v-model="selectedLibrary" @change="getDocuments">
                <option v-for="lib in libraries" :key="lib.id" :value="lib.id">{{ lib.name }}</option>
            </select>
            </div>

            <div v-if="selectedLibrary">
            <br/>
            &nbsp;<button v-if="folderPath" @click="goUp"> <i class="fas fa-arrow-up"></i> </button>
            <table class="table" width="100%">
                <thead>
                <tr>
                    <th>Bestandsnaam</th>
                    <th>Laatst gewijzigd</th>
                    <th>Grootte</th>
                    <th>Open</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="doc in documents" :key="doc.id">
                        <td>
                            <i class="fas fa-folder" v-if="doc.folder"></i>
                            <i class="fas fa-file" v-else></i>
                            {{ doc.name }}
                        </td>
                        <td>{{ doc.lastModifiedDateTime }}</td>
                        <td>{{ formatSizeUnits(doc.size) }}</td>
                        <td>
                            <button class="bg-emerald-300 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" v-if="doc.folder" @click="openFolder(doc.name)">Open</button>
                            <button class="bg-emerald-400 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" v-else @click="sendFileToServer(doc)">Selecteer</button>
                        </td>
                    </tr>   
                </tbody>
            </table>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        openModal: false,
        sites: [],
        libraries: [],
        documents: [],
        selectedSite: '',
        selectedLibrary: '',
        selectedDocument: '',
        folderPath: ''
      };
    },
    methods: {
      getSites() {
        // You'd need to replace this with your actual API call to get SharePoint sites
        axios
          .get('https://conan.antsolutions.be/conanbe/getSites/', { withCredentials: true })
          .then(response => {
            console.log(response);
            this.sites = response.data.value;
          })
          .catch(error => {
            console.error(error);
          });
      },
      getDocumentLibraries() {
        axios
            .get(`https://conan.antsolutions.be/conanbe/getDocLibraries/?siteUrl=${this.selectedSite}`, { withCredentials: true })
            .then(response => {
            console.log(response);
            this.libraries = response.data; // <- change this
            })
            .catch(error => {
            console.error(error);
            });
      },
      getDocuments() {
        // Add folderPath to the API request
        axios
        .get(`https://conan.antsolutions.be/conanbe/getMSDocs/?libraryId=${this.selectedLibrary}&folderPath=${this.folderPath}`, { withCredentials: true })
        .then(response => {
            console.log(response);
            this.documents = response.data;
        })
        .catch(error => {
            console.error(error);
        });
      },
      formatSizeUnits(size) {
        if(size >= 1073741824) {
            return (size / 1073741824).toFixed(2) + ' GB';
        } else if(size >= 1048576) {
            return (size / 1048576).toFixed(2) + ' MB';
        } else if(size >= 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else {
            return size + ' Bytes';
        }
      },
      openFolder(folderName) {
            // Append the folderName to the current folderPath
            if (this.folderPath === '') {
            this.folderPath = folderName;
            } else {
            this.folderPath = `${this.folderPath}/${folderName}`;
            }
            // Fetch the documents in the sub-folder
            this.getDocuments();
        },
        goUp() {
            // Remove the last segment from the folderPath
            const pathSegments = this.folderPath.split('/');
            pathSegments.pop();
            this.folderPath = pathSegments.join('/');
            // Fetch the documents in the parent directory
            this.getDocuments();
        },
      sendDocumentToDjango() {
        // You'd need to replace this with your actual API call to send the selected Document's URL to Django
        axios
          .post('https://conan.antsolutions.be/conanbe/processDocument/', { documentUrl: this.selectedDocument }, { withCredentials: true })
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.error(error);
          });
      },
    },
    created() {
      this.getSites();
    },
  };
  </script>


<style scoped>
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css";
.modal {
  display: block; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  margin-left: 30%;
  border: 1px solid #888;
  width: 60%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

table {
  border-collapse: collapse;
}
th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
</style>