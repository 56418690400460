<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <transition :name="transitionName" mode="out-in">
        <div v-if="!show2FA && !showForgotPassword && !showQRSetup" key="login-form" class="w-full lg:w-4/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold">
                <br/>
                <small>Log in met je gegevens</small>
              </div>
              <form @submit.prevent="login">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="username">Gebruikersnaam</label>
                  <input v-model="username" type="text" id="username" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Gebruikersnaam" />
                </div>
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="password">Wachtwoord</label>
                  <input v-model="password" type="password" id="password" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Wachtwoord" />
                </div>
                <div class="text-center mt-6">
                  <button class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Inloggen</button>
                </div>
              </form>
              <div class="flex flex-wrap mt-6 relative">
                <div class="w-full text-center">
                  <a @click="showForgotPasswordForm" class="text-blueGray-800 cursor-pointer"><small>Wachtwoord vergeten?</small></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="showQRSetup" key="qr-setup" class="w-full lg:w-4/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold">
                <br/>
                <small>Authenticator app instellen voor 2FA</small>
              </div>
              <div class="qr-code-container text-center">
                <QRCode :value="qrCodeData" class="qr-code" />
                <br /><br/>
                <p style="margin-bottom: 20px;">
                  Scan de QR-code met je authenticator app naar keuze (Google Authenticator, Microsoft Authenticator, ...). Klik dan op 'Klaar'.
                </p>
                <br />
                <button
                  @click="readyForOtp"
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                >
                  Klaar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="show2FA" key="2fa-form" class="w-full lg:w-4/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold">
                <br/>
                <small>Voer de 2FA-code in van je authenticator app</small>
              </div>
              <form @submit.prevent="verifyOtp">
                <div class="relative w-full mb-3">
                  <input v-model="otpCode" type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full" placeholder="2FA Code">
                </div>
                <div class="text-center mt-6">
                  <button class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">
                    Verifiëren
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div v-else-if="showForgotPassword" key="forgot-password-form" class="w-full lg:w-4/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
            <div class="absolute top-0 left-0 pt-4 pl-4" style="z-index: 1000; padding-top: 1rem;">
              <button @click="backToLogin" class="text-blueGray-800 hover:text-blueGray-600">
                <i class="fa fa-arrow-left"></i>
              </button>
            </div>
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div class="text-blueGray-400 text-center mb-3 font-bold">
                <br/>
                <small>Wachtwoord opnieuw instellen</small>
              </div>
              <form @submit.prevent="resetPassword">
                <div class="relative w-full mb-3">
                  <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="email">E-mail</label>
                  <input v-model="email" type="email" id="email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" placeholder="Email" required />
                </div>
                <div class="text-center mt-6">
                  <button class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Reset link opsturen</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <CardAlert
      v-show="alertMessage"
      ref="cardAlert"
      :message="alertMessage"
      @close="alertMessage = 'Hello'"
      timeout=7500
    />
  </div>
</template>
<script>
import axios from 'axios'; // Make sure axios is imported
import CardAlert from '@/components/Cards/CardAlert.vue';
import QRCode from 'qrcode.vue'
import { generateDeviceId, getStoredDeviceId } from '@/utils/device';

export default {
  components: {
    CardAlert,
    QRCode,
  },
  data() {
    return {
      alertMessage: '',
      username: '',
      password: '',
      email: '',
      showForgotPassword: false,
      transitionName: 'slide-left',
      show2FA: false,
      showQRSetup: false, // Additional state for QR setup step
      qrCodeData: '',
      rawOtpCode: '',
      deviceID: getStoredDeviceId() || generateDeviceId(),
    };
  },
  computed: {
    otpCode: {
      // Format display of OTP code with a space after the 3rd digit
      get() {
        return this.rawOtpCode.replace(/(\d{3})(\d{3})/, '$1 $2');
      },
      // Remove any spaces and update the raw OTP code value
      set(value) {
        this.rawOtpCode = value.replace(/\s+/g, '');
      },
    },
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('https://conan.antsolutions.be/conanbe/api-token-auth/', {
          username: this.username,
          password: this.password,
          device_id: this.deviceID,
        });

        if (response.data.otp_required || response.data.twofa_setup_required) {
          this.show2FA = true;
          // Store the temporary token for OTP verification
          this.tempToken = response.data.temp_token;

          if (response.data.twofa_setup_required) {
            this.show2FA = false; // Ensure the OTP input is not shown yet
            this.showQRSetup = true; // Show the QR code setup
            const secret = response.data.secret;
            this.qrCodeData = `otpauth://totp/Conan:${this.username}?secret=${secret}&issuer=ANT`;
          }
        } else {
          // Successful login without 2FA verification
          console.log(response.data);
          this.handleSuccessfulLogin(response.data);
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.error == "Invalid credentials"){
          this.alertMessage = "Je gebruikersnaam en/of paswoord is niet correct. Probeer opnieuw."
        }
        else{
          this.alertMessage = error.response.data.error || 'Er heeft zich een fout voorgedaan. Probeer opnieuw.';
        }
      }
    },
    async verifyOtp() {
      try {
        // Use the rawOtpCode for the request, ensuring it has no spaces
        const response = await axios.post('https://conan.antsolutions.be/conanbe/verify-otp/', {
          otp: this.rawOtpCode, // Use the raw OTP code without spaces
          token: this.tempToken,
          device_id: this.deviceID,
        }, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        // Handling the response remains the same
        if (response.data.message === '2FA verified, login successful') {
          this.handleSuccessfulLogin(response.data.token);
        } else {
          this.alertMessage = 'Er was een fout bij de verificatie. Probeer opnieuw.';
        }
      } catch (error) {
        console.error(error);
        this.alertMessage = error.response.data.error || 'Er was een fout bij de verificatie van je OTP code.';
      }
    },
    // Other methods
    handleSuccessfulLogin(data) {
      localStorage.setItem('user-token', data.token);
      localStorage.setItem('user-username', data.username);
      this.$router.push('/admin/rmtool/collections');
    },
    showForgotPasswordForm() {
      this.showForgotPassword = true;
      this.transitionName = 'slide-left';
    },
    backToLogin() {
      this.showForgotPassword = false;
      this.transitionName = 'slide-right';
    },
    show2faSetup(secret) {
      this.qrCodeData = `otpauth://totp/Conan:${this.username}?secret=${secret}&issuer=ANT`;
      this.showQRCode = true;
    },
    readyForOtp() {
      // Transition from QR code setup to OTP input
      this.showQRSetup = false;
      this.show2FA = true;
    },
    async resetPassword() {
      try {
        const response = await axios.post('https://conan.antsolutions.be/conanbe/requestPasswordReset/', {
          email: this.email,
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        this.alertMessage = response.data.message;
        this.$refs.cardAlert.showAlert();
        this.showForgotPassword = false;  // Optionally redirect back to the login form after submitting.
      } catch (error) {
        console.error('Error requesting password reset:', error);
        this.alertMessage = 'Er heeft zich een fout voorgedaan bij je wachtwoord aanvraag. Probeer opnieuw.';
      }
    },
  }
};
</script>
<style scoped>
.slide-left-enter-active, .slide-left-leave-active,
.slide-right-enter-active, .slide-right-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-left-enter, .slide-left-leave-to /* for moving to forgot password form */ {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-right-enter, .slide-right-leave-to /* for moving back to login form */ {
  transform: translateX(100%);
  opacity: 0;
}
.qr-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.qr-code {
  margin: 20px;
  width: 200px; /* Or your desired size */
  height: 200px; /* Make sure this matches the width for a square QR code */
}
</style>