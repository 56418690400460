<template>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">
        <UploadRMTResultCard/>
        <CardSharepointFiles/>
      </div>
      <!-- <div class="w-full mb-12 px-4">
        <card-table color="dark" />
      </div> -->
    </div>
  </template>
  <script>
  import UploadRMTResultCard from "@/components/Cards/UploadRMTResultCard.vue";
  import CardSharepointFiles from "@/components/Cards/CardSharepointFiles.vue";
  
  export default {
    components: {
      UploadRMTResultCard,
      CardSharepointFiles,
    },
  };
  </script>
  