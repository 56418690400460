export const generateDeviceId = () => {
  // Generate a more persistent unique device ID. 
  // This example uses a combination of timestamp and random numbers. 
  // Consider using a more sophisticated approach if needed.
  const uniqueSegment = Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  return 'device_' + uniqueSegment;
};

export const getStoredDeviceId = () => {
  // Check if a device ID already exists in local storage
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    // If not, generate a new device ID
    deviceId = generateDeviceId();
    // Store the newly generated ID in local storage
    localStorage.setItem('device_id', deviceId);
  }
  return deviceId;
};