<template>
  <div id="analysis" class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <div>
        <div class="px-4 md:px-10 mx-auto w-full">
          <div>
            <!-- Card stats -->
            <div id="textCardLine1" class="flex flex-wrap" style="margin-bottom: 50px">
              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                <card-stats
                  :loading="loading"
                  statSubtitle="Totale omvang"
                  :statTitle="totalSize.toString()"
                  :statPercent="TotalSizeDiff"
                  statDescripiron="sinds de laatste controle"
                  statIconName="far fa-hard-drive"
                  statIconColor="bg-emerald-500"
                  modal-id="auditModal"
                  :resultObject="auditObject"
                  modalTitle="Details (Audit)"
                />
              </div>
              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                <card-stats
                  :loading="loading"
                  statSubtitle="Aantal mappen"
                  :statTitle="totalCheckedMaps.toString()"
                  :statPercent="TotalSizeDiff"
                  statDescripiron="sinds de laatste controle"
                  statIconName="fas fa-folder"
                  statIconColor="bg-orange-500"
                  modal-id="auditModal"
                  :resultObject="auditObject"
                  modalTitle="Details (Audit)"
                />
              </div>
              <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
                <card-stats
                  :loading="loading"
                  statSubtitle="Aantal documenten"
                  :statTitle="totalCheckedDocs.toString()"
                  :statPercent="TotalDocsDiff"
                  statDescripiron="sinds de laatste controle"
                  statIconName="fas fa-file"
                  statIconColor="bg-pink-500"
                  modal-id="auditModal"
                  :resultObject="auditObject"
                  modalTitle="Details (Audit)"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="documentAgeChart" v-if="lineChartData && lineChartData.labels.length">
          <ReusableCardLineChart
            :loading="loading"
            :chartData="lineChartData"
            chartTitle="Oudste document"
            chartSubtitle="Aantal mappen gewijzigd per jaar"
          />
        </div>
        <div v-else>
          
        </div>
      </div>
      <div id="textCardLine2" class="flex flex-wrap" style="margin-top: 50px; margin-bottom: 50px">
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Identieke documenten"
            :statTitle="totalIDDocs.toString()"
            :statPercent="IDDocContentDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-copy"
            statIconColor="bg-red-500"
            modal-id="idDocsModal"
            :resultObject="IDDocsObject"
            modalTitle="Details (Identieke documenten)"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Documenten met identieke naam"
            :statTitle="totalIDNameDocs.toString()"
            :statPercent="IDDocNameDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-copy"
            statIconColor="bg-red-500"
            modal-id="idDocsModal"
            :resultObject="IDDocsObject"
            modalTitle="Details (Identieke documenten)"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Docs met identieke inhoud & naam"
            :statTitle="(completelyIDDocs.length - 1).toString()"
            :statPercent="CompletelyIDDocsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-copy"
            statIconColor="bg-red-500"
            modal-id="idDocsModal"
            :resultObject="IDDocsObject"
            modalTitle="Details (Identieke documenten)"
          />
        </div>
      </div>
      <div id="pieChartLine1" class="flex w-full items-stretch">
        <div class="w-6/12 p-2">
          <ReusableCardPieChart
            :loading="loading"
            :chartData="documentPieData"
            :alternateChartData="alternatePieData"
            chartTitle="Documenten per extensie"
            chartSubtitle="Documenttypes (top 10 aantal)"
            alternateChartSubtitle="Documenttypes (top 10 grootte in KB)"
            @segmentClicked="handleSegmentClick"
          />
        </div>
        <div class="w-6/12 p-2">
          <ReusableCardPieChart
            :loading="loading"
            :chartData="pieData"
            chartTitle="Uitgepakt, deels uitgepakt, volledig uitgepakt"
            chartSubtitle="ZIP-bestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
      </div>
      <div id="textCardLine3" class="flex flex-wrap" style="margin-top: 50px; margin-bottom: 50px">
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Corrupte documenten"
            :statTitle="totalCorruptDocs"
            :statPercent="CorruptDocsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-file-excel"
            statIconColor="bg-black"
            modal-id="cleanMapsModal"
            :resultObject="cleanMapsObject"
            modalTitle="Details (Schonen van mappen)"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Lege mappen"
            :statTitle="totalEmptyMaps"
            :statPercent="EmptyMapsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-folder-open"
            statIconColor="bg-teal-500"
            modal-id="cleanMapsModal"
            :resultObject="cleanMapsObject"
            modalTitle="Details (Schonen van mappen)"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Identieke naam, ander formaat"
            :statTitle="totalIDDocsDifferentFormat"
            :statPercent="IDDocDiffFormatDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-clone"
            statIconColor="bg-purple-500"
            modal-id="cleanMapsModal"
            :resultObject="cleanMapsObject"
            modalTitle="Details (Schonen van mappen)"
          />
        </div>
      </div>
      <div id="unsupportedFormatsChart" v-if="chartData">
        <ReusableCardBarChart
          :loading="loading"
          :chartData="chartData"
          chartTitle="Schonen van mappen"
          chartSubtitle="Niet-ondersteunde formaten"
        />
      </div>
      <div id="textCardLine4" class="flex flex-wrap" style="margin-top: 50px; margin-bottom: 50px">
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Verkeerde extensie"
            :statTitle="totalWrongExtension"
            :statPercent="WrongExtensionDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-file-excel"
            statIconColor="bg-orange-500"
            modal-id="wrongExtensionModal"
            :resultObject="wrongExtensionsObject"
            modalTitle="Details (Foutieve extensies)"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Ongeldige snelkoppelingen"
            :statTitle="totalWrongShortcuts"
            :statPercent="WrongShortcutsDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-share-square"
            statIconColor="bg-red-500"
            modal-id="shortcutsModal"
            :resultObject="shortcutsObject"
            modalTitle="Details (Snelkoppelingen)"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-4/12 px-4">
          <card-stats
            :loading="loading"
            statSubtitle="Beveiligd met wachtwoord"
            :statTitle="totalPasswordProtected"
            :statPercent="ProtectedwPassDiff"
            statDescripiron="sinds de laatste controle"
            statIconName="far fa-window-close"
            statIconColor="bg-teal-500"
            modal-id="protectedModal"
            :resultObject="protectedObject"
            modalTitle="Details (Beveiligde documenten)"
          />
        </div>
      </div>
      <div id="donutChartLine1" class="flex w-full items-stretch">
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            :chartData="systemFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Systeembestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            :chartData="templateFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Sjabloonbestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
      </div>
      <div id="donutChartLine2" class="flex w-full items-stretch">
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            :chartData="compressionFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Compressiebestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
        <div class="w-6/12 p-2">
          <ReusableCardDonutChart
            :loading="loading"
            :chartData="backupFormatsChartData"
            chartTitle="Schonen van mappen"
            chartSubtitle="Backupbestanden"
            @segmentClicked="handleSegmentClick"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <button
          v-if="!loading"
          @click="exportChartsToPDF"
          class="flex items-center justify-center bg-red-500 text-white font-semibold px-4 py-2 rounded shadow-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50 transition ease-in duration-200"
        >
          <i class="fas fa-file-pdf mr-2"></i> <!-- Ensure you're using the correct Font Awesome class -->
          Exporteer naar PDF
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import 'bootstrap';
import axios from 'axios';
import ReusableCardBarChart from "@/components/Cards/ReusableCardBarChart.vue";
import ReusableCardLineChart from "@/components/Cards/ReusableCardLineChart.vue";
import ReusableCardPieChart from "@/components/Cards/ReusableCardPieChart.vue";
import ReusableCardDonutChart from "@/components/Cards/ReusableCardDonutChart.vue";
import CardStats from "@/components/Cards/CardStats.vue";
import html2canvas from 'html2canvas';
import logo from '@/assets/img/conan.png';
import { jsPDF } from 'jspdf';
//import ReusableCardTable from "@/components/Cards/ReusableCardTable.vue";

// Helper function to convert Excel date serial number to JavaScript Date object.
// Excel date serial numbers are the number of days since January 0, 1900
function excelSerialDateToJSDate(serial) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400; 
  return new Date(utc_value * 1000);
}

export default {
  props: ['batchId'],
  components: {
    ReusableCardBarChart,
    ReusableCardPieChart,
    ReusableCardLineChart,
    ReusableCardDonutChart,
    CardStats,
    // ReusableCardTable,
  },
  data() {
    return {
      logoSrc: '/img/conan.png',
      batchData: null,
      processedBatchData: null,
      documentTypesObject: {},
      IDDocsObject: {},
      oldNewMapsObject: {},
      cleanMapsObject: {},
      ZIPFilesObject: {},
      wrongExtensionsObject: {},
      shortcutsObject: {},
      auditObject: {},
      protectedObject: {},
      chartData: {
      labels: [],
      datasets: []
      }, // Declare chartData here
      unsupportedFormatsChartData: {
      labels: [],
      datasets: []
      },
      systemFormatsChartData: {
      labels: [],
      datasets: []
      },
      templateFormatsChartData: {
      labels: [],
      datasets: []
      }, 
      compressionFormatsChartData: {
      labels: [],
      datasets: []
      }, 
      backupFormatsChartData: {
      labels: [],
      datasets: []
      }, 
      IDDocsData: null,
      totalSize: "0",
      totalSizeAccurate: "0",
      totalCheckedMaps: "0",
      totalCheckedDocs: "0",
      auditData: null,
      totalIDDocs: "0",
      totalIDNameDocs: "0",
      completelyIDDocs: [0],
      totalZipFiles: "0",
      totalUnpackedZips: "0",
      totalPackedZips: "0",
      totalPartlyUnpackedZips: "0",
      totalCorruptDocs: "0",
      totalEmptyMaps: "0",
      totalIDDocsDifferentFormat: "0",
      totalWrongExtension: "0",
      totalPasswordProtected: "0",
      totalWrongShortcuts: "0",
      pieData: {
      labels: [],
      datasets: []
      },
      currentTableData: [], // This will hold the data to display in the table
      // Data arrays
      gedeeltelijkuitgepaktezipbes: [], // Fill these with actual data
      nietuitgepaktezipbestanden: [],
      vollediguitgepaktezipbestand: [],
      lineChartData: {
      labels: [],
      datasets: []
      },
      documentPieData: {
      labels: [],
      datasets: []
      },     
      alternatePieData: {
      labels: [],
      datasets: []
      },
      oldTotalSize: 0,
      oldTotalDocs: 0,
      oldTotalMaps: 0,
      oldIDDocName: 0,
      oldIDDocContent: 0,
      oldCompletelyIDDocs: 0,
      oldCorruptDocs: 0,
      oldEmptyMaps: 0,
      oldIDDocDiffFormat: 0,
      oldWrongExtension: 0,
      oldWrongShortcuts: 0,
      oldProtectedwPass: 0,
      TotalSizeDiff: 0,
      TotalDocsDiff: 0,
      TotalMapsDiff: 0,
      IDDocNameDiff: 0,
      IDDocContentDiff: 0,
      CompletelyIDDocsDiff: 0,
      CorruptDocsDiff: 0,
      EmptyMapsDiff: 0,
      IDDocDiffFormatDiff: 0,
      WrongExtensionDiff: 0,
      WrongShortcutsDiff: 0,
      ProtectedwPassDiff: 0,
      loading: true,
    };
  },

  methods: {
    async loadLogo() {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = logo; // Use the imported logo
  });
},
    async captureChart(chartElement) {
  try {
    const canvas = await html2canvas(chartElement);
    return canvas.toDataURL('image/png');
  } catch (error) {
    console.error('Error capturing chart:', error);
  }
},
    async exportChartsToPDF() {
      const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
    format: 'a4'
  });

  const margin = 20;
  let pdfWidth = pdf.internal.pageSize.getWidth();
  let pdfHeight = pdf.internal.pageSize.getHeight();
  let pdfYPosition = margin;

  // Load and add logo
  try {
    const logoImg = await this.loadLogo();
    const logoWidth = 100; // Set as needed
    const logoHeight = logoImg.height * (logoWidth / logoImg.width); // Maintain aspect ratio
    const logoX = (pdfWidth - logoWidth) / 2; // Center the logo
    pdf.addImage(logoImg, 'PNG', logoX, pdfYPosition, logoWidth, logoHeight);
    pdfYPosition += logoHeight + margin;
  } catch (error) {
    console.error('Error loading logo:', error);
  }

  // List of chart component selectors (update this with your actual chart IDs or refs)
  const chartSelectors = [
    '#textCardLine1', 
    '#documentAgeChart',
    '#textCardLine2',
    '#pieChartLine1',
    '#textCardLine3',
    '#unsupportedFormatsChart',
    '#textCardLine4',
    '#donutChartLine1',
    '#donutChartLine2'
  ];

  for (let selector of chartSelectors) {
    const chartElement = document.querySelector(selector);
    if (chartElement) {
      const chartImage = await this.captureChart(chartElement);
      if (!chartImage) {
        console.log(`No image captured for chart: ${selector}, skipping...`);
        continue; // Skip if no image was captured
      }

      let img = new Image();
      img.src = chartImage;
      await new Promise(resolve => { img.onload = resolve });

      let canvasAspectRatio = img.width / img.height;
      let imgWidth = pdfWidth - (2 * margin); // Adjust width for margins
      let imgHeight = imgWidth / canvasAspectRatio;

      if (pdfYPosition + imgHeight > pdfHeight - margin) {
        pdf.addPage();
        pdfYPosition = margin; // Reset Y position at the top of the new page
      }

      pdf.addImage(chartImage, 'PNG', margin, pdfYPosition, imgWidth, imgHeight);
      pdfYPosition += imgHeight + margin; // Update Y position for the next chart
    } else {
      console.log(`Chart element not found for selector: ${selector}`);
    }
  }

  pdf.save('charts_analysis.pdf');
},


// // Helper function to load an image and return it as a data URL
// // Helper function to load an image and return it as a data URL
// async loadImage() {
//   const url = "https://conan.solutions/wp-content/uploads/2023/09/imageedit_8_5860604835-300x97.png"
//   console.log(url);
//   return new Promise((resolve, reject) => {
//     let img = new Image();
//     img.crossOrigin = 'Anonymous';
//     img.onload = () => resolve(img);
//     img.onerror = reject;
//     img.src = url;
//   }).then(img => {
//     // Create a canvas to convert the image to data URL format
//     let canvas = document.createElement('canvas');
//     canvas.width = img.width;
//     canvas.height = img.height;
//     let ctx = canvas.getContext('2d');
//     ctx.drawImage(img, 0, 0);
//     return canvas.toDataURL('image/png');
//   });
// },



    fetchData(batchId) {
      //axios.get(`https://conan.antsolutions.be/conanbe/fetchExcelResultById/${batchId}/`)
      const token = localStorage.getItem('user-token');
      axios.get(`https://conan.antsolutions.be/conanbe/fetchExcelResultById/${batchId}/`, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }})
        .then(response => {
          this.loading = false;
          console.log(response.data);
          const dataAsString = response.data;
          // Replace 'NaN' with '"NaN"' (the quotes are important)
          const correctedDataString = dataAsString.replace(/\bNaN\b/g, '"NaN"');

          const parsedData = JSON.parse(correctedDataString);
          console.log(parsedData);
          this.batchData = parsedData.data;
          this.generateFoldersLastChangedPerYearChart(this.batchData);
          this.generateIDDocData(this.batchData);
          this.generateAuditData(this.batchData);
          this.generateZipData(this.batchData);
          this.generateDocTypeChart(this.batchData);
          this.generateFoldersLastChangedPerYearLineChart(this.batchData);
          this.generateCleanMapsData(this.batchData);
          this.generateSystemFormatsChart(this.batchData);
          this.generateTemplateFormatsChart(this.batchData);
          this.generateCompressionFormatsChart(this.batchData);
          this.generateBackupFormatsChart(this.batchData);
          this.generateLastTextCardsData(this.batchData);
          this.generatePercentageDifferences(parsedData.last_batch_data_points);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    round(value, decimals) {
      return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    },
        // Function to find the value based on the key in overzicht
findValueByKey(overzicht, searchKey) {
    // Use the find method to locate the object where key 0 matches the searchKey
    const result = overzicht.find(item => item[0] === searchKey);
    
    // Return the value corresponding to key 1 or undefined if not found
    return result ? result[1] : undefined;
},
    generateFoldersLastChangedPerYearChart(data) {
  // Extracting the data
  const fileData = data[4][Object.keys(data[4])[0]]['nietondersteundeformaten'].slice(1); // Assuming first row is header

  // Function to extract file extension
  const extractExtension = (filePath) => {
    const parts = filePath.split('.');
    return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;
  };

  // Object to store count of each extension
  let extensionCounts = {};

  fileData.forEach(item => {
    const extension = extractExtension(item[0]);
    if (extension) {
      extensionCounts[extension] = (extensionCounts[extension] || 0) + 1;
    }
  });

  // Convert to array and sort by count
  const sortedExtensions = Object.entries(extensionCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 30); // Selecting top 30

  // Splitting into labels and data for the chart
  const labels = sortedExtensions.map(ext => ext[0]);
  const dataCounts = sortedExtensions.map(ext => ext[1]);

  // Create the chart data structure
  const chartData = {
    labels: labels,
    datasets: [{
      label: 'Aantal documenten',
      backgroundColor: '#4dc9f6',
      borderColor: '#4dc9f6',
      data: dataCounts,
      fill: false,
    }]
  };

  this.chartData = chartData;
    },
    generateFoldersLastChangedPerYearLineChart(folderData) {
      let foldersPerYear = {};

      const foldersData = folderData[3][Object.keys(folderData[3])[0]]['oudemappen'].slice(1); // Skip header row

      for (const folder of foldersData) {
        let year = excelSerialDateToJSDate(folder[1]).getFullYear();
        if (!foldersPerYear[year]) {
          foldersPerYear[year] = 0;
        }
        foldersPerYear[year]++;
      }

      const labels = Object.keys(foldersPerYear).sort();
      const chartDataValues = labels.map(year => foldersPerYear[year]);

      const chartData = {
        labels: labels,
        datasets: [{
          label: 'Laatste mapwijzigingen per jaar',
          backgroundColor: "#4dc9f6",
          borderColor: "#4dc9f6",
          data: chartDataValues,
          fill: false,
        }],
      };
      this.lineChartData = chartData;
      console.log(this.lineChartData.labels.length);
      console.log(this.lineChartData);
    },
    generateIDDocData(data){
      // Access the nested data within the first object of the array
      console.log(data);
      console.log(data[2][Object.keys(data[2])[0]]);
      this.totalIDDocs = this.IDDocsData = data[2][Object.keys(data[2])[0]]['overzicht'][5][1];
      this.totalIDNameDocs = this.IDDocsData = data[2][Object.keys(data[2])[0]]['overzicht'][6][1];
      this.IDDocsObject = data[2][Object.keys(data[2])[0]];

      console.log(data[2][Object.keys(data[2])[0]]['identiekedocumentnaam']);
      var concatIDDocNames = data[2][Object.keys(data[2])[0]]['identiekedocumentnaam'].map(obj => obj[0] + obj[1]).slice(1);
      var concatIDDocs = data[2][Object.keys(data[2])[0]]['identiekeinhoud'].map(obj => obj[0] + obj[1]).slice(1);
      this.completelyIDDocs = concatIDDocNames.filter(value => concatIDDocs.includes(value));
      console.log(concatIDDocNames);
      console.log(concatIDDocs);
      console.log(this.completelyIDDocs);


      // // Extract the data from the object and populate the chartData
      // for (const item of nestedData) {
      //     if (isFinite(item[1]) && item[0].startsWith("Aantal documenten van het type") && item[0] !== "Aantal documenten van het type '*'") {
      //         chartData.labels.push(item[0]);
      //         chartData.datasets[0].data.push(Number(item[1]));
      //     }
      // }
      console.log(this.IDDocsData);
    },
    generateAuditData(data){
      // Access the nested data within the first object of the array
      console.log(data[1][Object.keys(data[1])[0]]['overzicht'][2][1]);
      this.auditObject = data[1][Object.keys(data[1])[0]];

      this.totalCheckedMaps = data[1][Object.keys(data[1])[0]]['overzicht'][2][1];
      this.totalCheckedDocs = data[1][Object.keys(data[1])[0]]['overzicht'][3][1];

      // // Extract the data from the object and populate the chartData
      // for (const item of nestedData) {
      //     if (isFinite(item[1]) && item[0].startsWith("Aantal documenten van het type") && item[0] !== "Aantal documenten van het type '*'") {
      //         chartData.labels.push(item[0]);
      //         chartData.datasets[0].data.push(Number(item[1]));
      //     }
      // }
      console.log(this.IDDocsData);
    },
    generatePercentageDifferences(data){
      console.log(data);
      console.log(typeof data.totaleOmvang);
      if (typeof data.totaleOmvang != "undefined"){
        const calcPercentageDiff = (oldValue, newValue) => {
          newValue = parseFloat(newValue, 10);

          if (oldValue === 0 && newValue === 0) {
            return 0; // No change if both are zero
          } else if (oldValue === 0) {
            return 100; // Consider as 100% increase if old value was zero
          }

          // Calculate the percentage difference
          const percentageDiff = ((newValue - oldValue) / Math.abs(oldValue)) * 100;

          // Return the result rounded to two decimal places
          return Math.round(percentageDiff * 100) / 100;
        };
        this.oldTotalSize=data.totaleOmvang;
        this.oldTotalDocs=data.aantalDocumenten;
        this.oldTotalMaps=data.totaalDoorzochteMappen;
        this.oldIDDocName=data.totalIDNameDocs;
        this.oldIDDocContent=data.totalIDDocs;
        this.oldCompletelyIDDocs=data.completelyIDDocsAmount;
        this.oldCorruptDocs=data.corruptFormat;
        this.oldEmptyMaps=data.legeMappen;
        this.oldIDDocDiffFormat=data.documentenMetzelfdeNaam;
        this.oldWrongExtension=data.wrongExtensionsAmount;
        this.oldWrongShortcuts=data.snelkoppelingenAmount;
        this.oldProtectedwPass=data.passwordCount;

        this.TotalSizeDiff = calcPercentageDiff(this.oldTotalSize, this.totalSizeAccurate);
        this.TotalDocsDiff = calcPercentageDiff(this.oldTotalDocs, this.totalCheckedDocs);
        this.TotalMapsDiff = calcPercentageDiff(this.oldTotalMaps, this.totalCheckedMaps);
        this.IDDocNameDiff = calcPercentageDiff(this.oldIDDocName, this.totalIDNameDocs);
        this.IDDocContentDiff = calcPercentageDiff(this.oldIDDocContent, this.totalIDDocs);
        // Note: completelyIDDocs.length should be handled appropriately in data to be used here
        this.CompletelyIDDocsDiff = calcPercentageDiff(this.oldCompletelyIDDocs, this.completelyIDDocs.length-1);
        this.CorruptDocsDiff = calcPercentageDiff(this.oldCorruptDocs, this.totalCorruptDocs);
        this.EmptyMapsDiff = calcPercentageDiff(this.oldEmptyMaps, this.totalEmptyMaps);
        this.IDDocDiffFormatDiff = calcPercentageDiff(this.oldIDDocDiffFormat, this.totalIDDocsDifferentFormat);
        this.WrongExtensionDiff = calcPercentageDiff(this.oldWrongExtension, this.totalWrongExtension);
        this.WrongShortcutsDiff = calcPercentageDiff(this.oldWrongShortcuts, this.totalWrongShortcuts);
        this.ProtectedwPassDiff = calcPercentageDiff(this.oldProtectedwPass, this.totalPasswordProtected);

        console.log(this.TotalSizeDiff.toString());
    }
    },
    generateCleanMapsData(data){
      // Access the nested data within the first object of the array
      console.log(data[4][Object.keys(data[4])[0]]);
      this.cleanMapsObject = data[4][Object.keys(data[4])[0]];
      console.log(data);
      this.totalCorruptDocs = data[4][Object.keys(data[4])[0]]['overzicht'][10][1];
      this.totalEmptyMaps = data[4][Object.keys(data[4])[0]]['overzicht'][11][1];
      this.totalIDDocsDifferentFormat = data[4][Object.keys(data[4])[0]]['overzicht'][12][1];
    },
    generateLastTextCardsData(data){
      // Access the nested data within the first object of the array
      this.protectedObject = data[6][Object.keys(data[6])[0]];
      let count = 0;
      data[6][Object.keys(data[6])[0]]['internebeveiliging'].forEach(item => {
        // Assuming the structure is like data[6][Object.keys(data[6])[0]]['internebeveiliging']
        if (item[2].includes("beveiligd (wachtwoord voor openen)")) {
          count++;
        }
      });
      this.wrongExtensionsObject = data[7][Object.keys(data[7])[0]];
      this.shortcutsObject = data[8][Object.keys(data[8])[0]];
      this.totalWrongExtension = data[7][Object.keys(data[7])[0]]['verkeerdeextensie'].length-1;
      this.totalPasswordProtected = count;
      this.totalWrongShortcuts = data[8][Object.keys(data[8])[0]]['overzicht'][6][1];
    },
    handleSegmentClick(label) {
      if (label === 'Label for gedeeltelijkuitgepaktezipbes') {
        this.currentTableData = this.gedeeltelijkuitgepaktezipbes;
      } else if (label === 'Label for nietuitgepaktezipbestanden') {
        this.currentTableData = this.nietuitgepaktezipbestanden;
      } else {
        // Default to vollediguitgepaktezipbestand
        this.currentTableData = this.vollediguitgepaktezipbestand;
      }
    },
    generateZipData(data){
      // Access the nested data within the first object of the array
      // Ensure you are working with the correct item in the data array
      const item = data[5];

      // Retrieve the key for the first file in this item
      const firstFileName = Object.keys(item)[0];

      // Access the 'overzicht' array within this file
      const overzicht = item[firstFileName]['overzicht'];

      // Access the third element of the 'overzicht' array
      // and then its second property
      if (overzicht && overzicht.length > 2) {
          this.totalZipFiles = overzicht[4];
          this.totalUnpackedZips = overzicht[5];
          this.totalPartlyUnpackedZips = overzicht[7];
          this.totalPackedZips = overzicht[9];

    //       const pieChartData = {
    //         labels: [],
    //         datasets: [{
    //           backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', /* more colors as needed */],
    //           data: []
    //         }]
    //       };

    // // Access the nested data within the first object of the array
    // const nestedData = data[0][Object.keys(data[0])[0]]['overzicht'];
    this.vollediguitgepaktezipbestand = item[firstFileName]['vollediguitgepaktezipbestand'];
    this.gedeeltelijkuitgepaktezipbes = item[firstFileName]['gedeeltelijkuitgepaktezipbes'];
    this.nietuitgepaktezipbestanden = item[firstFileName]['nietuitgepaktezipbestanden'];
    this.currentTableData = this.vollediguitgepaktezipbestand;
    console.log(this.vollediguitgepaktezipbestand);
    // Assume this.totalSize is needed as before
    this.totalSizeAccurate = data[0][Object.keys(data[0])[0]]['overzicht'][7][1];
    console.log("total size:");
    console.log(data[0][Object.keys(data[0])[0]]['overzicht'][7][1]);
    //this.totalSize = data[0][Object.keys(data[0])[0]]['overzicht'][7][1].slice(0,3) + " MB";
    this.totalSize = data[0][Object.keys(data[0])[0]]['overzicht'][7][1];

    // Accessing the values using the find function
    this.totalUnpackedZips = this.findValueByKey(overzicht, "Aantal uitgepakte ZIP-bestanden:");
    this.totalPartlyUnpackedZips = this.findValueByKey(overzicht, "Aantal gedeeltelijk uitgepakte ZIP-bestanden:");
    this.totalPackedZips = this.findValueByKey(overzicht, "Totaal aantal ZIP-bestanden:");

    // Log the values to confirm
    console.log(this.totalUnpackedZips);
    console.log(this.totalPartlyUnpackedZips);
    console.log(this.totalPackedZips);

    // this.totalUnpackedZips = overzicht[5][1];
    // this.totalPartlyUnpackedZips = overzicht[7][1];
    // console.log(overzicht);
    // this.totalPackedZips = overzicht[9][1];

    this.pieData = {
      labels: ['Uitgepakte ZIPs', 'Gedeeltelijk uitgepakte ZIPs', 'Niet-uitgepakte ZIPs'],
      datasets: [{
        data: [this.totalUnpackedZips, this.totalPartlyUnpackedZips, this.totalPackedZips],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
      }]
    };

      } else {
          console.log('Overzicht array is too short or does not exist');
      }
    },
    generateDocTypeChart(data) {
      // Create the structure for pie chart data
      const pieChartData = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [], // Add as many colors as you have data points
          hoverBackgroundColor: [] // Optionally, specify hover colors
        }],
      };

      // Access the nested 'overzicht' data
      const nestedData = data[0][Object.keys(data[0])[0]]['overzicht'];
      console.log(data[0][Object.keys(data[0])[0]]);
      // Filter out the unwanted items and sort by the count of documents in descending order
      const filteredData = nestedData
        .filter(item => 
          isFinite(item[1]) && 
          item[0].startsWith("Aantal documenten") && 
          item[0] !== "Aantal documenten '*'" &&
          !item[0].endsWith("'*'")
          )
        .sort((a, b) => b[1] - a[1]) // Sort by the count of documents
        .slice(0, 10); // Take only the top 10
      console.log(filteredData);
      // Define an array of colors for the pie chart segments
      const backgroundColors = [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#4BC0C0',
      '#9966FF',
      '#FF9F40',
      '#4D5360',
      '#EA6B66',
      '#70AD47',
      '#A3A1FB',
      ];


      // Loop through the filteredData array and populate the pie chart data
      filteredData.forEach((item, index) => {
        // This pattern matches both "Aantal documenten van het type 'XYZ'" and "Aantal documenten 'XYZ'",
// capturing only the "XYZ" part for further processing.
const match = item[0].match(/Aantal documenten (van het type )?'(.+?)'/);

// If there's a match, use the captured group (the document type without the prefix and quotes).
// If there's no match (which should be rare, given your description), fall back to the original item.
const label = match ? match[2] : item[0];

pieChartData.labels.push(label); // Use the cleaned up label for your chart

        pieChartData.datasets[0].data.push(item[1]);
        pieChartData.datasets[0].backgroundColor.push(backgroundColors[index % backgroundColors.length]); // Cycle through colors if there are more data points than colors
      });

      console.log("piechartdata: ");
      console.log(pieChartData);

      // Update your component's pie chart data property
      this.documentPieData = pieChartData;

      const nestedAlternateData = data[0][Object.keys(data[0])[0]]['alledocumenten'].slice(1);

      // Function to extract the file extension
      const extractExtension = (filename) => {
        const parts = filename.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : null;
      };

      // Object to store the total size of each extension
      let extensionSizes = {};

      nestedAlternateData.forEach(file => {
        const filename = file[1]; // Assuming the filename is at index 1
        const sizeKB = file[3]; // Assuming the size in KB is at index 3
        const ext = extractExtension(filename);

        if (ext) {
          extensionSizes[ext] = (extensionSizes[ext] || 0) + sizeKB;
        }
      });

      // Convert to array and sort by size
      const sortedExtensions = Object.entries(extensionSizes)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10); // Take top 10

      // Split into labels and data for the chart
      const labels = sortedExtensions.map(ext => ext[0]);
      const alternateData = sortedExtensions.map(ext => ext[1]);

      // Create the chart data structure
      const chartData = {
        labels: labels,
        datasets: [{
          data: alternateData,
          backgroundColor: backgroundColors.slice(0, labels.length), // Use only as many colors as needed
          hoverBackgroundColor: backgroundColors.slice(0, labels.length)
        }]
      };

      // Update the chart data
      this.alternatePieData = chartData;

    },
    generateSystemFormatsChart(data) {
      // Access the nested 'overzicht' data
      console.log(data[4][Object.keys(data[4])[0]]['systeembestanden']);
      const nestedData = data[4][Object.keys(data[4])[0]]['systeembestanden'].slice(1);
        // Create the structure for pie chart data
        const systemFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });

          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);

          // Preparing chart data
          systemFormatChartData.labels = sortedFormats.map(item => item[0]);
          systemFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);

          // Update the chart data property
          this.systemFormatsChartData = systemFormatChartData;
    },
    generateTemplateFormatsChart(data) {
      // Access the nested 'overzicht' data
      console.log(data[4][Object.keys(data[4])[0]]['sjabloonbestanden']);
      const nestedData = data[4][Object.keys(data[4])[0]]['sjabloonbestanden'].slice(1);
        // Create the structure for pie chart data
        const templateFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });

          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);

          // Preparing chart data
          templateFormatChartData.labels = sortedFormats.map(item => item[0]);
          templateFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);

          // Update the chart data property
          this.templateFormatsChartData = templateFormatChartData;
    },
    generateCompressionFormatsChart(data) {
      // Access the nested 'overzicht' data
      console.log(data[4][Object.keys(data[4])[0]]['compressiebestanden']);
      const nestedData = data[4][Object.keys(data[4])[0]]['compressiebestanden'].slice(1);
        // Create the structure for pie chart data
        const compressionFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });
          console.log(nestedData);
          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);
          
          // Preparing chart data
          compressionFormatChartData.labels = sortedFormats.map(item => item[0]);
          compressionFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);
          console.log(compressionFormatChartData);
          // Update the chart data property
          this.compressionFormatsChartData = compressionFormatChartData;
    },
    generateBackupFormatsChart(data) {
      // Access the nested 'overzicht' data
      console.log(data[4][Object.keys(data[4])[0]]['autosaveenbackupbestanden']);
      const nestedData = data[4][Object.keys(data[4])[0]]['autosaveenbackupbestanden'].slice(1);
        // Create the structure for pie chart data
        const backupFormatChartData = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
                '#9966FF', '#FF9F40', '#4D5360', '#EA6B66',
                '#70AD47', '#A3A1FB'
              ],
              hoverBackgroundColor: [] // Optionally, specify hover colors
            }],
          };

          // Extracting file extensions and counting occurrences
          let formatCounts = {};
          nestedData.forEach(item => {
            const filePath = item[0];
            const extension = filePath.split('.').pop();
            if (extension) {
              formatCounts[extension] = (formatCounts[extension] || 0) + 1;
            }
          });

          // Sorting by count and taking the top 10
          const sortedFormats = Object.entries(formatCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10);

          // Preparing chart data
          backupFormatChartData.labels = sortedFormats.map(item => item[0]);
          backupFormatChartData.datasets[0].data = sortedFormats.map(item => item[1]);

          // Update the chart data property
          this.backupFormatsChartData = backupFormatChartData;
    }
  },
  created() {
    this.fetchData(this.batchId);
  },
};
</script>
