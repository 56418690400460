<template>
    <div class="flex flex-wrap mt-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6">
        <!-- Explanation Box -->
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
          <div class="rounded-t mb-0 px-4 py-3 border-0" style="background-color: #F7FAFC;">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-lg text-blueGray-700">
                  Collecties
                </h3>
              </div>
            </div>
          </div>
          <div class="block w-full bg-white overflow-x-auto px-4 py-3">
            <!-- Explanation content -->
            <p class="text-blueGray-600 text-sm leading-relaxed">
              Beheer hier je collecties. Een collectie bevat een of meerdere analyses, waarvan je de evolutie kan volgen. <br/>Je kan een nieuwe collectie aanmaken door de naam in te voeren en op 'Voeg toe' te klikken. Bestaande collecties kunnen worden bekeken en beheerd vanuit de onderstaande tabel.<br/><br/>Een collectie gaat over eenzelfde hoofdmap.
            </p>
          </div>
        </div>
        <div class="block w-full overflow-x-auto shadow-lg">
          <!-- Projects table -->
          <table class="items-center w-full border-collapse">
            <thead>
              <tr>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Collectie
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Hoofdmap
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Aanmaakdatum
                </th>
                <th class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                </th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="collection in collections" :key="collection.collection_id" @click="navigateToUploadResults(collection.collection_id)" class="hoverable-row cursor-pointer bg-white hover:bg-blueGray-500 transition-colors duration-150">
                <td class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ collection.collection_name }}
                </td>
                <td v-if="collection.hoofdmap != null" class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ collection.hoofdmap }}
                </td>
                <td v-if="collection.hoofdmap == null" class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    <i>Nog geen data toegevoegd aan deze collectie</i>
                </td>
                <td class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                    {{ collection.creation_date }}
                </td>
                <td class="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                  <button @click.stop="deleteCollection(collection.collection_id)" class="text-white bg-red-500 hover:bg-red-700 font-bold py-1 px-3 rounded inline-flex items-center">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-full flex flex-wrap mt-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded">
          <div class="rounded-t mb-0 px-4 py-3 border-0" style="background-color: #F7FAFC;">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-lg text-blueGray-700">
                  Maak nieuwe collectie
                </h3>
              </div>
            </div>
          </div>
          <div class="block w-full overflow-x-auto px-4">
            <!-- Form content -->
            <div class="flex items-center mb-4">
              <input v-model="newCollectionName" type="text" placeholder="Nieuwe collectie naam" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" style="transition: all 0.15s ease;">
              <button @click="createCollection" class="ml-2 text-white active:bg-blueGray-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" style="background-color: #10B981; " type="button">
                <i class="fas fa-plus"></i> Voeg toe
              </button>
            </div>
          </div>
        </div>
      </div>
      <CardAlert
        v-show="alertMessage"
        ref="cardAlert"
        :message="alertMessage"
        @close="alertMessage = 'Hello'"
      />
    </div>
  </template>
  <style>
  .hoverable-row {
    transition: background-color 150ms ease-in-out; /* Smooth transition for background color change */
  }
  
  .hoverable-row:hover {
    background-color: #f1f5f9; /* This is the approximate color code for Tailwind's blueGray-500 */
  }
  </style>
  
  <script>
  import axios from 'axios';
  import CardAlert from '@/components/Cards/CardAlert.vue';
  export default {
    components: {
    CardAlert,
  },
    data() {
      return {
        collections: [],
        alertMessage: '',
        newCollectionName: '', // Data model for the new collection name input
      };
    },
    methods: {
      navigateToUploadResults(collectionId) {
        this.$router.push({ path: '/admin/rmtool/uploadresults', query: { collection_id: collectionId } });
      },
      fetchCollections() {
  const token = localStorage.getItem('user-token');
  console.log(token);
  axios.get('https://conan.antsolutions.be/conanbe/fetchCollections/', {
    headers: { Authorization: `Token ${token}` } // Make sure this matches the expected format
  })
  .then(response => {
    console.log(response);
    this.collections = response.data;
  })
  .catch(error => {
    console.log(error.response);
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access, e.g., redirect to login page or show login modal
      this.$router.push('/login');
    } else if (error.response && error.response.status === 403) {
      // Handle forbidden access, might be due to missing or incorrect token
      console.error("Access forbidden. Check if the token is correct.", error);
    } else {
      console.error("There was an error fetching the collections:", error);
    }
  });
},


createCollection() {
  // Basic validation
  if (!this.newCollectionName.trim()) {
    alert('Collection name is required');
    return;
  }
  const token = localStorage.getItem('user-token');
  console.log(token);
  axios.post('https://conan.antsolutions.be/conanbe/createCollection/', {
    collection_name: this.newCollectionName, // This is the data payload
  }, {
    headers: { Authorization: `Token ${token}` }, // Headers should be a separate argument
  })
  .then(() => {
    this.alertMessage = `Collectie aangemaakt!`;
    this.$refs.cardAlert.showAlert();
    this.newCollectionName = ''; // Reset input
    this.fetchCollections(); // Refresh the list of collections
  })
  .catch(error => {
    console.error("There was an error creating the collection:", error.response);
  });
},
deleteCollection(collectionId) {
  const token = localStorage.getItem('user-token');
  axios.delete(`https://conan.antsolutions.be/conanbe/deleteCollection/${collectionId}/`, {
    headers: { Authorization: `Token ${token}` }
  })
  .then(() => {
    this.alertMessage = `Collectie verwijderd!`;
    this.$nextTick(() => {
        if (this.$refs.cardAlert) {
            this.$refs.cardAlert.showAlert();
        }
    });
    this.fetchCollections(); // Refresh the collections list
  })
  .catch(error => {
    console.error("There was an error deleting the collection:", error);
    // Handle error
  });
},
    },
    mounted() {
      this.fetchCollections();
    },
  };
  </script>
  
  