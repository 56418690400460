<template>
    <transition name="fade">
      <div v-if="visible" class="custom-alert" :class="`alert-${type}`">
        <span>{{ message }}</span>
        <button class="close-btn" @click="hideAlert">✕</button>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      type: {
        type: String,
        default: 'success',
      },
      message: String,
      color: {
        type: String,
        default: 'green', // Default color, can be adjusted
      },
      timeout: {
        type: Number,
        default: 5000,
      }
    },
    data() {
      return {
        visible: false,
      };
    },
    methods: {
      showAlert() {
        this.visible = true;
        setTimeout(this.hideAlert, this.timeout);
      },
      hideAlert() {
        this.visible = false;
      },
    },
    mounted() {
      this.showAlert();
    },
  };
  </script>
  
  <style scoped>
.custom-alert {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  padding: 15px;
  margin-bottom: 15px; /* Adjust as needed for spacing from the bottom */
  border: 1px solid transparent;
  border-radius: 4px;
  z-index: 1050; /* Ensures alert appears above most other content */
  transition: all 0.3s ease;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
  </style>
  