<template>
  <div>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6">
        <ReusableCardLineChart chartTitle="Evolutie" chartSubtitle="Bestandsgrootte in MB" :chartData="chartData" />
    </div>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
      <div class="px-4 py-3 border-b-0">
        <div class="flex flex-wrap items-center justify-between">
          <div class="relative w-full max-w-full flex-grow flex-1">
            <h3
              class="font-bold text-2xl"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              Upload RMTool resultaten
            </h3>
            <h2>Upload exports van resultaten uit de RMTool. Dit kan in enkel in .xlsx-formaat.</h2>
          </div>
          <div class="flex-shrink-0">
            <button
              class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              @click="uploadFiles"
            >
              Upload resultaten
            </button>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 border-b-0">
        <div class="flex flex-wrap items-center justify-between">
          <div class="w-4/12">
            <h1><b>Documenttypes</b></h1>
            <input
              type="file"
              @change="onFileChangeDocType"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/><br/>
            <h1><b>Audit</b></h1>
            <input
              type="file"
              @change="onFileChangeAudit"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/><br/>
            <h1><b>Identieke documenten</b></h1>
            <input
              type="file"
              @change="onFileChangeIdDocs"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/>
          </div>
          <div class="w-4/12">
            <h1><b>Oude/nieuwe mappen</b></h1>
            <input
              type="file"
              @change="onFileChangeOldNewFolders"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/><br/>
            <h1><b>Schonen van mappen</b></h1>
            <input
              type="file"
              @change="onFileChangeCleanFolders"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/><br/>
            <h1><b>Uitgepakte ZIP-bestanden</b></h1>
            <input
              type="file"
              @change="onFileChangeZIPFiles"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/>
          </div>
          <div class="w-4/12">
            <h1><b>Beveiligde documenten</b></h1>
            <input
              type="file"
              @change="onFileChangeSecureDocs"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/><br/>
            <h1><b>Foutieve bestandsformaten</b></h1>
            <input
              type="file"
              @change="onFileChangeFileFormats"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/><br/>
            <h1><b>Gecontroleerde snelkoppelingen</b></h1>
            <input
              type="file"
              @change="onFileChangeShortcuts"
              accept=".xlsx"
              class="border rounded-lg px-3 py-2 w-full focus:outline-none focus:shadow-outline"
            /><br/>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 border-b-0">
        <div class="flex flex-wrap items-center justify-between">
          <div class="w-full">
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Gecontroleerde map
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Datum
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            Tijd
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          >
            # Bestanden
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            :class="[
              color === 'light'
                ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                : 'bg-emerald-800 text-emerald-300 border-emerald-700',
            ]"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
        </tr>
        <tr v-if="loading">
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
        </tr>
        <tr v-if="loading">
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
          <td style="padding: 5px;">
            <Skeleton count="5" />
          </td>
        </tr>
        <tr v-else v-for="(item, index) in data" :key="index">
          <td style="padding-bottom: 10px; padding-top: 15px;">{{ item.data[0][Object.keys(item.data[0])]['overzicht'][1][1] }}</td>
          <td>{{ formatDate(item.batch_upload_date) }}</td>
          <td>{{ formatTime(item.batch_upload_date) }}</td>
          <td style="padding-left: 50px;">{{ item.data.length }}</td>
          <td>
            <button
              class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              @click="$router.push({ name: 'result', params: { batchId: item.batch_id } })"
            >
              <i class="fa fa-magnifying-glass-chart"></i>
            </button>
            <button
              class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
              @click="deleteItem(item.batch_id)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import ReusableCardLineChart from "@/components/Cards/ReusableCardLineChart.vue";
  import { Skeleton } from 'vue-loading-skeleton';
  import "vue-loading-skeleton/dist/style.css"

  export default {
    data() {
      return {
        file: null,
        text: '',
        fileTopics: [],
        isLoading: false,
        loading: true, // Add this line
        data: null,
        chart: null,
        chartData: {
          labels: [],
          datasets: [
            
          ]
        },
        lastBatchDatapoints: {},
      };
    },
    mounted() {
      const collectionId = this.$route.query.collection_id;
      if (collectionId) {
        this.fetchData(collectionId);
      }
    },
    methods: {
      generateChart(data) {
          const chartData = {
              labels: [],
              datasets: [{
                  label: Object.keys(data)[0],
                  backgroundColor: "#ed64a6",
                  borderColor: "#ed64a6",
                  data: [],
                  fill: false,
                  barThickness: 8,
              }],
          };

          // Extract the data from the object and populate the chartData
          console.log(data);
          for (const item of data[Object.keys(data)[0]]) {
            console.log(item);
            if (isFinite(item[1]) && item[0].startsWith("Aantal documenten van het type") && item[0] !== "Aantal documenten van het type '*'") {
                chartData.labels.push(item[0]);
                chartData.datasets[0].data.push(Number(item[1]));
            }
          }

          this.chartData = chartData;
          console.log(this.chartData);
      },
      formatDate(datetime) {
        const date = new Date(datetime);
        return date.toLocaleDateString();
      },
      formatTime(datetime) {
        const date = new Date(datetime);
        return date.toLocaleTimeString();
      },
      onFileChangeDocType(event) {
      this.DocTypeResult = event.target.files[0];
      },
      onFileChangeAudit(event) {
        this.AuditResult = event.target.files[0];
      },
      onFileChangeIdDocs(event) {
        this.IdDocsResult = event.target.files[0];
      },
      onFileChangeOldNewFolders(event) {
        this.OldNewFoldersResult = event.target.files[0];
      },
      onFileChangeCleanFolders(event) {
        this.CleanFoldersResult = event.target.files[0];
      },
      onFileChangeZIPFiles(event) {
        this.ZIPFilesResult = event.target.files[0];
      },
      onFileChangeSecureDocs(event) {
        this.SecureDocsResult = event.target.files[0];
      },
      onFileChangeFileFormats(event) {
        this.FileFormatsResult = event.target.files[0];
      },
      onFileChangeShortcuts(event) {
        this.ShortcutsResult = event.target.files[0];
      },
      extractDataPoints(inputData) {
        console.log(inputData);
        const firstObject = inputData[0];
        const data = firstObject.data;

        // General data points from "Documenttypes.xlsx"
        const overzichtGeneral = data[0][Object.keys(data[0])[0]].overzicht;
        const totaleOmvang = overzichtGeneral[7][1];
        const totaalDoorzochteMappen = overzichtGeneral[5][1];
        const aantalDocumenten = overzichtGeneral[6][1];

        const wrongExtensions = data[7][Object.keys(data[7])[0]].verkeerdeextensie;
        const wrongExtensionsAmount = wrongExtensions.length-1;

        const snelkoppelingen = data[8][Object.keys(data[8])[0]].ongeldigesnelkoppelingen;
        const snelkoppelingenAmount = snelkoppelingen.length-1;

        let passwordCount = 0;
        data[6][Object.keys(data[6])[0]].internebeveiliging.forEach(item => {
              // Assuming the structure is like data[6][Object.keys(data[6])[0]]['internebeveiliging']
              if (item[2].includes("beveiligd (wachtwoord voor openen)")) {
                passwordCount++;
              }
            });

        // Data points from "SchonenVanMappen.xlsx"
        const overzichtSchonen = data[4][Object.keys(data[4])[0]].overzicht;
        console.log(overzichtSchonen);
        // Find the entry where key 0 matches "Documenten in corrupt formaat"
        const corruptEntry = overzichtSchonen.find(entry => entry[0] === "Documenten in corrupt formaat");

        // Set the value to 0 if the entry is not found, otherwise set it to the value at key 1
        const corruptFormat = corruptEntry ? corruptEntry[1] : 0;
        const legeMappen = overzichtSchonen[11][1]; // "Lege mappen"
        const documentenMetzelfdeNaam = overzichtSchonen[12][1]; // "Documenten met gelijkaardige naam"

        // Data points from "IdentiekeDocumenten.xlsx"
        const overzichtIDDocs = data[2][Object.keys(data[2])[0]].overzicht;
        const totalIDDocs = overzichtIDDocs[5][1];
        const totalIDNameDocs = overzichtIDDocs[6][1];

        // Identical documents processing
        const concatIDDocNames = data[2][Object.keys(data[2])[0]]['identiekedocumentnaam'].map(obj => obj[0] + obj[1]).slice(1);
        const concatIDDocs = data[2][Object.keys(data[2])[0]]['identiekeinhoud'].map(obj => obj[0] + obj[1]).slice(1);
        const completelyIDDocs = concatIDDocNames.filter(value => concatIDDocs.includes(value));
        const completelyIDDocsAmount = completelyIDDocs.length-1;

        // Logging the extracted values
        console.log("Totale Omvang:", totaleOmvang);
        console.log("Totaal Doorzochte Mappen:", totaalDoorzochteMappen);
        console.log("Aantal Documenten:", aantalDocumenten);
        console.log("Corrupt Format:", corruptFormat);
        console.log("Lege Mappen:", legeMappen);
        console.log("Documenten Met dezelfde Naam:", documentenMetzelfdeNaam);
        console.log("Total ID Docs:", totalIDDocs);
        console.log("Total ID Name Docs:", totalIDNameDocs);
        console.log("Completely Identical Docs:", completelyIDDocsAmount);
        console.log("Verkeerde extensie:", wrongExtensionsAmount);
        console.log("Ongeldige snelkoppelingen:", snelkoppelingenAmount);
        console.log("Wachtwoord voor openen:", passwordCount);

        this.lastBatchDatapoints = {
          totaleOmvang: parseFloat(totaleOmvang.split(" ")[0]), // Assuming "300.807 MB" format and you want just the number
          totaalDoorzochteMappen: parseInt(totaalDoorzochteMappen),
          aantalDocumenten: parseInt(aantalDocumenten),
          wrongExtensionsAmount: parseInt(wrongExtensionsAmount),
          snelkoppelingenAmount: parseInt(snelkoppelingenAmount),
          passwordCount: parseInt(passwordCount),
          corruptFormat: parseInt(corruptFormat),
          legeMappen: parseInt(legeMappen),
          documentenMetzelfdeNaam: parseInt(documentenMetzelfdeNaam),
          totalIDDocs: parseInt(totalIDDocs),
          totalIDNameDocs: parseInt(totalIDNameDocs),
          completelyIDDocsAmount: parseInt(completelyIDDocsAmount),
        };
        console.log(this.lastBatchDatapoints);
      },
      extractBatchData(inputData) {
        if (!inputData || inputData.length === 0) {
          console.log("No previous batch data available to extract datapoints from.");
          return; // Exit the method if there's no data to process
        }
            this.extractDataPoints(inputData);
            // Extract and transform data
            let transformedData = inputData.map(item => {
                const batchDate = item.batch_upload_date.split('T')[0]; // Extract only the date part
                console.log(item.data[0]);
                const overzicht = item.data[0][Object.keys(item.data[0])[0]].overzicht;
                console.log(overzicht);
                const sizeEntry = overzicht.find(entry => entry['0'].includes("Totale omvang van de documenten"));
                console.log(sizeEntry);
                const size = sizeEntry ? parseFloat(parseFloat(sizeEntry['1']).toFixed(2)) : 0; // Convert size to a number and round to 2 decimals
                console.log(size);
                return [batchDate, size];
            });

            // Sort by date (earliest first)
            transformedData.sort((a, b) => new Date(a[0]) - new Date(b[0]));

            // Create labels and dataset structure for chartData
            const chartData = {
                labels: transformedData.map(item => item[0]),
                datasets: [
                    {
                        label: 'Totale grootte (MB)',
                        data: transformedData.map(item => item[1]),
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }
                ]
            };
            console.log(chartData);
            return chartData;
      },
      uploadFiles() {
        let formData = new FormData();
        formData.append('doctype', this.DocTypeResult);
        formData.append('audit', this.AuditResult);
        formData.append('iddocs', this.IdDocsResult);
        formData.append('oldnewfolders', this.OldNewFoldersResult);
        formData.append('cleanfolders', this.CleanFoldersResult);
        formData.append('zipfiles', this.ZIPFilesResult);
        formData.append('securedocs', this.SecureDocsResult);
        formData.append('fileformats', this.FileFormatsResult);
        formData.append('shortcuts', this.ShortcutsResult);
        
        // Assuming 'currentCollectionId' holds the ID of the collection you want to associate the batch with
        // And your Django backend is set up to read this ID either from the request body or as a form-data field
        // Here, we'll add it as a form-data field
        const currentCollectionId = this.$route.query.collection_id;
        formData.append('collection_id', currentCollectionId);
        // Only append lastBatchDatapoints if it's not empty
        if(Object.keys(this.lastBatchDatapoints).length > 0) { // Check if lastBatchDatapoints is not empty
          formData.append('lastBatchDatapoints', JSON.stringify(this.lastBatchDatapoints));
        }
        console.log(formData);
        const token = localStorage.getItem('user-token');
        axios.post('https://conan.antsolutions.be/conanbe/uploadRMTResults/', formData, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          console.log(response);
          const collectionId = this.$route.query.collection_id;
          this.fetchData(collectionId);  // Refresh the data after uploading
        }).catch(error => {
          console.error(error);
        });
      },
      fetchData(collectionId = null) {
        this.loading = true; // Set loading to true before making the request
        let url = 'https://conan.antsolutions.be/conanbe/fetchExcelResults/';
        if (collectionId) {
          url += `?collection_id=${collectionId}`;
        }
        const token = localStorage.getItem('user-token');
        axios.get(url, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }})
          .then(response => {
            console.log(response);
            const correctedDataString = response.data.replace(/\bNaN\b/g, '"NaN"');
            const parsedData = JSON.parse(correctedDataString);
            this.data = parsedData;
            this.chartData = this.extractBatchData(parsedData);
            console.log(this.extractBatchData(parsedData));
            this.loading = false; // Set loading to false after data is processed
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            this.loading = false; // Set loading to false even if there's an error
          });
      },
      async deleteItem(batchId) {
        try {
          const token = localStorage.getItem('user-token');
          const response = await axios.delete(`https://conan.antsolutions.be/conanbe/deleteBatch/${batchId}`, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }});
          if (response.status === 200) {
            // Remove the item from the list in the front-end state
            this.items = this.items.filter(item => item.batch_id !== batchId);

            // Optionally, show a success message or trigger UI update
          } else {
            // Handle non-successful responses
            alert('Failed to delete item');
          }
        } catch (error) {
          console.error('Error deleting item:', error);
          alert('Failed to delete item');
        }
      },
    },
    created() {
      //this.fetchData();  // Fetch the data when the component is created
    },
    components: {
      Skeleton,
      ReusableCardLineChart,
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },
  };
  </script>
  <style>
 @keyframes pulse {
  0% {
    background-color: #f3f3f3;
  }
  50% {
    background-color: #ecebeb;
  }
  100% {
    background-color: #f3f3f3;
  }
}

.animate-pulse {
  animation: pulse 2s infinite;
}

.bg-gray-300 {
  background-color: #d1d5db;
}

.mb-4 {
  margin-bottom: 1rem;
}

.h-4 {
  height: 1rem;
}
  </style>